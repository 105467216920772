<template>
    <section>
        <modal ref="modalAprobarCambios" titulo="Aprobar Cambios" icon="check" @guardar="updateDatos">
            <p class="text-general text-center px-5">
                ¿Desea aprobar los cambios realizados de la {{ $config.vendedor }} ?
            </p>
        </modal>
    </section>
</template>
<script>
import CambioDatos from "../../../../services/tiendas/cambio_datos";

export default {
    data(){
        return{
            model: {
                id:null,
                id_tienda:null,
                tipo: 1
            }
        }
    },
    methods: {
        toggle(model){
            this.model.id = model.id
            this.model.id_tienda = model.id_tienda
            this.$refs.modalAprobarCambios.toggle();
        },
        async updateDatos(){
            try {
                const {data} = await CambioDatos.updateDatos(this.model)
                this.notificacion('Mensaje', 'Cambios aceptados', 'success')
                this.$emit('actualizar')
                this.$refs.modalAprobarCambios.toggle()
                this.model.id = null
                this.model.id_tienda = null
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

